import html2canvas from "html2canvas";

import logoPurple from "../../assets/media/logo/company/nvreLogoPurple.jpeg";
import redQR from "../../assets/media/logo/qrCode/nvre-red-QR.jpeg";
import wechatQRFollow from "../../assets/media/logo/qrCode/nvre-wechat-subscribe-qrcode.jpeg";
import wechatQRContact from "../../assets/media/logo/qrCode/nvre-wechat-qrcode.jpeg";

export async function preparePDFData(
  contents: any,
  filterRules: any,
  changedFilters: any,
  isMobile: boolean,
  onProgress: (progress: number) => void,
  abortSignal: AbortSignal
) {
  const frontPageHTML = createFrontPageHTML(
    filterRules,
    changedFilters,
    isMobile
  );
  const frontPageElement = document.createElement("div");
  frontPageElement.id = "front-page-container";
  frontPageElement.innerHTML = frontPageHTML;
  const frontPageImage = await nonBlockingHtml2Canvas(frontPageElement, {
    scale: 3,
    useCORS: true,
    logging: true,
    // allowTaint: true,
    // ignoreElements: function (e: any) {
    //   // Here, ignore external URL links and lazyload images
    //   if ((e.tagName === "A" && e.host !== window.location.host) || e.getAttribute('loading') === "lazy") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    onclone: function (clonedDoc: Document) {
      const frontPageElement = clonedDoc.querySelector(
        "#front-page-container"
      ) as HTMLElement;
      if (frontPageElement) {
        Object.assign(frontPageElement.style, {
          width: "297mm",
          height: "210mm",
        });
      }
    },
  });

  onProgress(50);
  if (abortSignal.aborted) {
    onProgress(0);
    return null;
  }

  const tubeViewImage = await nonBlockingHtml2Canvas(contents["tube-view"], {
    scale: 4,
    useCORS: true,
    logging: true,
    // allowTaint: true,
    // ignoreElements: function (e: any) {
    //   // Here, ignore external URL links and lazyload images
    //   if ((e.tagName === "A" && e.host !== window.location.host) || e.getAttribute('loading') === "lazy") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    onclone: function (clonedDoc: Document) {
      const style = clonedDoc.createElement("style");
      style.textContent = `
        #keys-to-lines {
          display: none !important;
        }

        #print-tube-view {
          visibility: visible !important;
          display: block !important;
        }
        `;
      clonedDoc.head.appendChild(style);
    },
  });

  onProgress(80);
  if (abortSignal.aborted) {
    onProgress(0);
    return null;
  }

  const listViewImage = await nonBlockingHtml2Canvas(contents["list-view"], {
    scale: 3,
    useCORS: true,
    logging: true,
    // allowTaint: true,
    // ignoreElements: function (e: any) {
    //   // Here, ignore external URL links and lazyload images
    //   if ((e.tagName === "A" && e.host !== window.location.host) || e.getAttribute('loading') === "lazy") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    onclone: function (clonedDoc: Document) {
      const html = clonedDoc.getElementsByTagName("html")[0];
      if (html) {
        html.style.setProperty("font-size", "6px", "important");
        const style = clonedDoc.createElement("style");
        style.textContent = `
        #print-list-view {
         visibility: visible !important;
  display: grid !important;
  width: 100vw !important;
  grid-template-columns: repeat(4, 1fr) !important;
  grid-auto-rows: 18mm !important;
  column-gap: 2mm !important;
  row-gap: 1mm !important;
  padding: 0.5mm 2mm !important;
  font-size: 5px !important;
  overflow: hidden !important;
}

#print-list-view .development_small_container {
height: 17.5mm !important;
  border: 1px solid rgb(208, 208, 208) !important;
  border-radius: 10px !important;
  font-size: inherit !important;
}

#print-list-view .development_small_container img{
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

#print-list-view .info-dev-content {
  font-size: 5.2px !important;
  padding: 0.75mm 1.5mm !important;
  line-height: normal !important;
  margin-top: -1mm !important;
}

#print-list-view .info-dev-content > div:first-of-type {
  font-size: 10px !important;
  margin-bottom: 0mm !important;
}

#print-list-view .info-dev-content > div:first-of-type h2 {
  line-height: 10px !important;
  margin-bottom: 1mm !important;
  gap: 1mm !important;
}

#print-list-view .info-dev-content > div:last-of-type {
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  gap: 1.75rem !important;
}

#print-list-view .info-dev-content > div:last-of-type > ul:first-of-type {
  width: 24mm !important;
}

#print-list-view .info-dev-content > div:last-of-type .price-list {
  display: -webkit-flex;
  display: flex;
  gap: 0.25rem !important;
}

#print-list-view .info-dev-content > p {
  margin-bottom: 0.5mm !important;
}

#print-list-view .info-dev-content .cn {
  display: block !important;
  margin-right: 1mm !important;
  position: absolute !important;
  bottom: 2mm !important;
  left: 2mm !important;
  background-color: rgba(233, 230, 237, 0.85) !important;
  padding: 0.5mm !important;
  padding-top: 0.25mm !important;
  border-radius: 1mm;
  color: rgb(36, 36, 36) !important;
  font-size: 6px !important;
  font-weight: 500 !important;
  border: 0.5px solid rgba(255, 255, 255, 0.8) !important;
}

#print-list-view .info-dev-content .cn span {
  display: block !important;
  -webkit-transform: translateY(-0.5mm) !important;
  transform: translateY(-0.5mm) !important;
}

#print-list-view .info-dev-content svg {
  -webkit-transform: translateY(0.85mm) !important;
  transform: translateY(0.85mm) !important;
  height: 2.5mm !important;
  width: 2.5mm !important;
}
        `;

        if (isMobile) {
          style.textContent += `
#print-list-view {
  grid-template-columns: repeat(3, 1fr) !important;
  grid-auto-rows: 34.32mm !important;
  column-gap: 4mm !important;
  row-gap: 2mm !important;
  padding: 1mm 4mm !important;
  font-size: 10px !important;
}

#print-list-view .development_small_container {
  height: auto !important;
}

#print-list-view .development_small_container .flex-container {
  grid-template-columns: 1fr 4fr !important;
}

#print-list-view .info-dev-content {
  font-size: 10px !important;
  padding: 0.35rem 0.85rem !important;
  line-height: normal !important;
  margin-top: -2mm !important;
}

#print-list-view .info-dev-content > div:first-of-type {
  font-size: 20px !important;
}

#print-list-view .info-dev-content > div:first-of-type h2 {
  line-height: 18px !important;
  margin-bottom: 2mm !important;
}

#print-list-view .info-dev-content > div:last-of-type {
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  gap: 1.25rem !important;
}

#print-list-view .info-dev-content > div:last-of-type > ul:first-of-type {
  width: 40% !important;
  -webkit-flex-direction: column !important;
  flex-direction: column !important;
}

#print-list-view .info-dev-content > p {
  margin-bottom: 1mm !important;
}

#print-list-view .info-dev-content .cn {
  font-size: 8.5px !important;
}

#print-list-view .development_small_container .info-dev-content .with_img {
  width: 20mm !important;
  height: 36mm !important;
  overflow: hidden !important;
}

#print-list-view .development_small_container .info-dev-content img {
  -webkit-object-fit: contain !important;
  object-fit: contain !important;
  -webkit-object-position: center center !important;
  object-position: center center !important;
}

#print-list-view .info-dev-content svg {
  -webkit-transform: translateY(1.25mm) !important;
  transform: translateY(1.25mm) !important;
  height: 5mm !important;
  width: 5mm !important;
}

          `;
        }

        clonedDoc.head.appendChild(style);
      }
    },
  });

  onProgress(100);
  if (abortSignal.aborted) {
    onProgress(0);
    return null;
  }

  return {
    frontPageImage,
    tubeViewImage,
    listViewImage,
    isMobile,
    filterRules,
    changedFilters,
  };
}

function createFrontPageHTML(
  filterRules: any,
  changedFilters: any,
  isMobile: boolean
): string {
  const filterChanged = Object.keys(changedFilters).length > 0;
  const now = new Date();

  return `
      <div class="w-full h-full border p-10 flex flex-col justify-between">
        <div>
          <img src=${logoPurple} class="w-[40mm]" />
          <p class="text-gray-400/50 font-poster text-[1.5rem] mt-3">Elegant Living, Confident Investing</p>
          <p class="text-gray-400/50 font-poster-cn text-[1.2rem]">优雅生活，自信投资</p>
          <p class="text-[3.5rem] font-bold text-secondary-dark mt-16 flex items-end gap-2"><span>伦敦新开发楼盘${
            filterChanged ? "筛选" : "总汇"
          }</span>
            ${
              isMobile
                ? `<span class="text-sm text-gray-200">手机版本</span>`
                : ""
            }
          </p>
          <p class="text-secondary-light text-[1.6rem] mt-3 font-medium">${now.getFullYear()}年${
    now.getMonth() + 1
  }月</p>
        </div>
        <div class="flex justify-between items-start">
          <div>
            <p class="text-[1.5rem] font-medium text-gray-400 border-b pb-4 mb-4">${
              filterChanged ? "筛选条件" : ""
            }</p>
            <table>
              <thead>
                <tr>
                  <td colspan="2" class="font-bold text-secondary-light text-[1.2rem] pb-3">地理位置</td>
                  <td colspan="2" class="font-bold text-secondary-light text-[1.2rem] pb-3">房产信息</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="w-[20mm] opacity-40">地铁区</td>
                  <td class="w-[70mm]">${
                    filterChanged && changedFilters["zone"]
                      ? _formatFilterZone(changedFilters["zone"])
                      : "1/2/3/4/5/6区"
                  }</td>
                  <td class="w-[20mm] opacity-40">价格</td>
                  <td class="w-[70mm]">${
                    filterChanged && changedFilters["price"]
                      ? "£" +
                        changedFilters["price"][0] +
                        " - £" +
                        changedFilters["price"][1]
                      : "£" +
                        filterRules["price"][0] +
                        " - £" +
                        filterRules["price"][1]
                  }</td>
                </tr>
                <tr>
                  <td class="w-[20mm] opacity-40">邮编</td>
                  <td class="w-[70mm]">${
                    filterChanged && changedFilters["postcode"]
                      ? _formatPostcode(changedFilters["postcode"], filterRules)
                      : "N/A"
                  }</td>
                  <td class="w-[20mm] opacity-40">户型</td>
                  <td class="w-[70mm]">${
                    filterChanged &&
                    (changedFilters["bedrooms"] || changedFilters["house"])
                      ? _formatFilterBedroomAndHouse(
                          changedFilters["bedrooms"],
                          changedFilters["house"]
                        )
                      : "开间，1/2/3/4/5房，公寓和别墅"
                  }</td>
                </tr>
                <tr>
                  <td class="w-[20mm] opacity-40">区域</td>
                  <td class="w-[70mm]">${
                    filterChanged && changedFilters["borough"]
                      ? changedFilters["borough"]
                      : "N/A"
                  }</td>
                  <td class="w-[20mm] opacity-40">预计交房</td>
                  <td class="w-[70mm]">${
                    filterChanged && changedFilters["completion"]
                      ? _formatFilterCompletion(changedFilters["completion"])
                      : "现房，2024，2025，2026，2027+"
                  }</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex flex-col justify-between gap-4 opacity-60">
            <ul class="leading-[4mm]">
              <li>
                <span class="inline-block w-[20mm] text-[0.8rem] text-gray-400"><b class="text-inherit-size">EMAIL:</b></span>
                <span class="text-[0.9rem]">contact@nvre.co.uk</span>
              </li>
              <li>
                <span class="inline-block w-[20mm] text-[0.8rem] text-gray-400"><b class="text-inherit-size">OFFICE:</b></span>
                <span class="text-[0.9rem]">+44 (0)20 3907 4024</span>
              </li>
              <li>
                <span class="inline-block w-[20mm] text-[0.8rem] text-gray-400"><b class="text-inherit-size">WEBSITE: </b></span>
                <span class="text-[0.9rem]">www.nvre.co.uk</span>
              </li>
              <li>
                <span class="inline-block w-[20mm] text-[0.8rem] text-gray-400"><b class="text-inherit-size">ADDRESS:</b></span>
                <span class="text-[0.9rem]">16 Berkeley Street, Mayfair</span>
              </li>
              <li>
                <span class="inline-block w-[20mm] text-[0.8rem] text-gray-400"><b class="text-inherit-size"></b></span>
                <span class="text-[0.9rem]">London, W1J 8DZ, UK</span>
              </li>
            </ul>
            <ul class="flex gap-6">
              <li class="flex flex-col items-center">
                <img class="h-[18mm]" src=${redQR} />
                <p class="text-[0.8rem] opacity-70">小红书</p>
              </li>
              <li class="flex flex-col items-center">
                <img class="h-[18mm]" src=${wechatQRFollow} />
                <p class="text-[0.8rem] opacity-70">微信关注</p>
              </li>
              <li class="flex flex-col items-center">
                <img class="h-[18mm]" src=${wechatQRContact} />
                <p class="text-[0.8rem] opacity-70">一对一咨询</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    `;
}

function nonBlockingHtml2Canvas(
  element: HTMLElement,
  options?: any
): Promise<string> {
  return new Promise((resolve, reject) => {
    const task = () => {
      const clonedElement = element.cloneNode(true) as HTMLElement;
      document.body.appendChild(clonedElement);
      clonedElement.style.position = "absolute";
      clonedElement.style.left = "-9999px";

      html2canvas(clonedElement, options)
        .then((canvas) => {
          console.log(
            "html2canvas completed, canvas size:",
            canvas.width,
            "x",
            canvas.height
          );
          document.body.removeChild(clonedElement);
          console.log(canvas);
          const dataURL = canvas.toDataURL("image/jpeg", 0.8);
          console.log("DataURL length:", dataURL.length);
          resolve(dataURL);
        })
        .catch((error) => {
          console.error("html2canvas error:", error);
          document.body.removeChild(clonedElement);
          reject(error);
        });
    };

    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(() => task());
    } else {
      setTimeout(task, 0);
    }
  });
}

// HELPER FUNCTIONS //
function _formatPostcode(filterPostcode: string, filterRules: any): string {
  return (
    filterPostcode +
    (filterRules["useRadius"] ? " within" + filterRules["radius"] + "km" : "")
  );
}

function _formatFilterZone(filterZone: string[]): string {
  let formattedZone = filterZone
    .sort(
      (a, b) =>
        parseInt(a.replace("zone-", "")) - parseInt(b.replace("zone-", ""))
    )
    .map((zone) =>
      zone
        .replace("zone-", "")
        .replace("one", "1")
        .replace("two", "2")
        .replace("three", "3")
        .replace("four", "4")
        .replace("five", "5")
        .replace("six", "6+")
    )
    .join("/");
  return formattedZone + "区";
}

function _formatFilterBedroomAndHouse(
  filterBedroom: string[],
  filterHouse: boolean
): string {
  let studioIndex = filterBedroom.indexOf("bed0");
  let studio = studioIndex !== -1 ? ["开间"] : [];
  let otherRooms = filterBedroom
    .filter((bedroom) => bedroom !== "bed0")
    .sort()
    .map((bedroom) => {
      if (bedroom === "bed5") {
        return "5+";
      } else {
        return bedroom.replace("bed", "");
      }
    });
  let formattedBedroom = [...studio, ...otherRooms].join("/");

  if (filterHouse) {
    return formattedBedroom + "房，仅别墅";
  } else {
    return formattedBedroom + "房，别墅和公寓";
  }
}

function _formatFilterCompletion(filterCompletion: string[]): string {
  let formattedCompletion = filterCompletion
    .sort()
    .map((year) => {
      if (year === "Completed") {
        return "现房";
      } else {
        return year.replace("yr", "");
      }
    })
    .join(", ");
  return formattedCompletion;
}
