// Libraries
import React, { useContext, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

// React components
import TubeView from "./Views/TubeView";
import ListView from "./Views/ListView";
import TableView from "./Views/TableView";
import MapView from "./Views/MapView";
import Filter from "./Controls/Filter";
import MenuBar from "./Controls/MenuBar";
import { SpinnerCircles } from "../../util/Spinners/Spinners";

// React context
import { SalesContainer, SalesContext } from "../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../types/types";

// Redux slices
import { AppDispatch } from "../../app/store";
import {
  getAllDevelopmentsStatus,
  getFilteredDevelopmentIds,
} from "../../features/developments/developmentsSlice";
import InfoCollection from "../../items/InfoCollection/InfoCollection";
import Help from "../../items/Help/Help";
import { GlobalContext } from "../../context/GlobalContext";
import {
  selectLayoutHeaderHeight,
  selectLayoutMenubarHeight,
} from "../../features/layouts/layoutsSlice";
import { preloadImage } from "../../util/loadResources/imagePreloader";
import imgMap from "../../assets/media/developmentMaps/map.jpeg";
import imgList from "../../assets/media/developmentMaps/list.jpeg";
import styles from "./SalePage.module.css";

export default function SalePage() {
  return (
    <SalesContainer>
      <Sale />
      <InfoCollection />
    </SalesContainer>
  );
}

function Sale() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const viewParam = query.get("view");

  const saleContainerRef = useRef(null);
  const viewRef = useRef<HTMLDivElement>(null);
  const [mapViewMounted, setMapViewMounted] = useState(false);

  const { showFilter, editMode } = useContext(SalesContext) as ISalesContext;
  const {
    view,
    setView,
    permission,
    setShowActionPopup,
    lang,
    showFilterCarriedOverNotification,
    setShowFilterCarriedOverNotification,
  } = useContext(GlobalContext) as IGlobalContext;

  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  // const loading = useSelector(getAllDevelopmentsStatus);
  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);

  useEffect(() => {
    const driver = localStorage.getItem("driver");

    if (driver !== "true") {
      setShowActionPopup(true);
      localStorage.setItem("driver", "true");

      const preloadImages = async () => {
        try {
          await Promise.all([preloadImage(imgMap), preloadImage(imgList)]);
        } catch (error) {
          console.error("Error preloading images:", error);
        }
      };

      preloadImages();
    }
  }, []);

  useEffect(() => {
    if (viewParam) {
      setView(viewParam);
    }
  }, [viewParam]);

  useEffect(() => {
    if (view === "map") {
      setMapViewMounted(true);
    }
  }, [view]);

  useEffect(() => {
    if (showFilterCarriedOverNotification) {
      const timer = setTimeout(() => {
        setShowFilterCarriedOverNotification(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showFilterCarriedOverNotification]);

  // if (loading === "loading") {
  //   return (
  //     <div className=" min-h-screen" style={{ marginTop: menubarHeight }}>
  //       <SpinnerCircles />
  //     </div>
  //   );
  // }

  return (
    <div
      className={`max-w-[1800px] h-full mx-auto relative z-0 flex flex-col overflow-hidden`}
      ref={saleContainerRef}
    >
      <div
        className={`fixed top-0 left-0 z-[9999999999999999] w-full h-full flex items-center justify-center ${
          showFilterCarriedOverNotification ? styles.fadeIn : styles.fadeOut
        } ${showFilterCarriedOverNotification ? "block" : "hidden"}`}
      >
        <div className="p-4 rounded-lg bg-gray-900 bg-opacity-60 shadow-lg">
          <p
            className="text-lg text-white"
            style={{ textShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
          >
            {lang ? "Your filters have been applied!" : "已为您显示筛选后结果"}
          </p>
        </div>
      </div>
      <MenuBar
        count={filteredDevelopmentIds.length}
        showSort={view === "list"}
      />

      <div
        ref={viewRef}
        className={`sale-content grid-svg-container  bg-gray-50 ${
          showFilter && "mr-[400px]"
        }`}
        style={{
          minHeight: "calc(100vh - " + (menubarHeight + headerHeight) + "px)",
          marginTop: menubarHeight + "px",
        }}
      >
        <div
          className={`${styles.viewContainer} ${
            view === "list" ? styles.active : styles.hidden
          }`}
        >
          <ListView />
        </div>

        <div
          className={`${styles.viewContainer} ${
            view === "tube" ? styles.active : styles.hidden
          }`}
        >
          <TubeView />
        </div>
        {permission > 4 && view === "table" && <TableView />}
        {mapViewMounted && (
          <div
            className={`${styles.viewContainer} ${
              view === "map" ? styles.active : styles.hidden
            }`}
          >
            <MapView rounded={false} onHomePage={false} />
          </div>
        )}
      </div>
      <Filter viewRef={viewRef} />
      <Help
        playTutorial={() => {
          const driverObj = createDriver(lang);
          driverObj.drive();
        }}
      />
    </div>
  );
}

/* FOR HIDE AND SHOW HEADER
    useEffect(() => {
      let timeoutId: any = null;
      let scrollTimeoutId: any = null;
      let lastScrollTop = 0;
      // let isScrolling = false;
  
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        // isScrolling = true;
  
        // if (timeoutId !== null) {
        //   clearTimeout(timeoutId);
        // }
  
        // timeoutId = setTimeout(() => {
        //   isScrolling = false;
        // }, 1000);
  
        if (scrollTop > lastScrollTop) {
          // Scrolling down
          setHeaderHeight("0");
        } else if (scrollTop == 0) {
          // User has reached the top of the page
  
  
  
  
          clearTimeout(scrollTimeoutId);
          clearTimeout(timeoutId);
  
          scrollTimeoutId = setTimeout(() => {
            // User has stopped scrolling at the top for 1 second
            console.log("User has stopped scrolling");
            timeoutId = setTimeout(() => {
              // Check if the user has scrolled up again after 1 second
              const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
              if (currentScrollTop < lastScrollTop) {
                console.log("User has scrolled up");
                setHeaderHeight(null);
              } else if (currentScrollTop > lastScrollTop) {
                console.log("User has scrolled down");
              } else {
                console.log("User has not scrolled");
              }
              // Update lastScrollTop only if the user has scrolled
              if (currentScrollTop !== lastScrollTop) {
                lastScrollTop = currentScrollTop;
              } else {
                console.log("User has scrolled up");
                setHeaderHeight(null);
              }
            }, 100);
          }, 1000);
        }
  
        lastScrollTop = scrollTop;
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        if (scrollTimeoutId !== null) {
          clearTimeout(scrollTimeoutId);
        }
      };
    }, []);
    */

export function createDriver(lang: boolean) {
  return driver({
    popoverClass: "driverjs-theme",
    showProgress: true,
    steps: [
      {
        element: "#driver_views",
        popover: {
          title: lang ? "Views" : "视图",
          description: lang
            ? "This section allows you to switch between different views of the developments."
            : "这个部分允许您在不同的房产项目视图之间切换。",
          side: "left",
          align: "start",
        },
      },
      {
        element: "#driver_filter",
        popover: {
          title: lang ? "Filter" : "筛选",
          description: lang
            ? "Here you can apply various filters to refine the developments displayed."
            : "在这里，您可以应用各种条件来筛选房产项目。",
          side: "right",
          align: "start",
        },
      },
      {
        element: "#driver_download_pdf",
        popover: {
          title: lang ? "Download PDF" : "下载PDF",
          description: lang
            ? "Click here to download the data in PDF format."
            : "点击此处以PDF格式下载筛选结果。",
          side: "bottom",
          align: "start",
        },
      },
      {
        element: "#driver_tools",
        popover: {
          title: lang ? "Tools" : "工具",
          description: lang
            ? "This section contains tools that are useful for (soon-to-be) property owners."
            : "这个部分包含对房产所有者有用的工具。",
          side: "right",
          align: "start",
        },
      },
      {
        popover: {
          title: lang ? "Tour Complete!" : "导览完成！",
          description: lang
            ? "That concludes the tour. Enjoy exploring developments in London!"
            : "导览到此结束。祝您愉快地探索伦敦的开发项目！",
        },
      },
    ],
    prevBtnText: lang ? "Previous" : "上一步",
    nextBtnText: lang ? "Next" : "下一步",
    doneBtnText: lang ? "Done" : "完成",
  });
}
