import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from 'react-dom';
import {
  removeDevelopment,
  getFilteredDevelopmentIds,
  getKeywordFilteredDevelopmentIds,
  Development,
} from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapView.scss";
import ReactDOMServer from "react-dom/server";
import Popup from "../Components/Common/Popup";
import { GlobalContext } from "../../../context/GlobalContext";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { getAllDevelopments } from "../../../features/developments/developmentsSlice";
import { selectLayoutHeaderHeight, selectLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3FpaSIsImEiOiJja3kzYXlnNHkwMGNpMnNwN2RtMGhxMjI4In0.RpbUFrXuGm4d_QjFNIYo9A";

export default function MapView(props: any) {
  const {
    lang,
    renderNum,
    searchName,
    permission,
    showingTools,
    isSmallScreen,
    showContactPopup,
    isMobile,
    isRealMobile,
    showActionPopup,
    showPermissionPopup,
    adminOn,
    // view,
    showFilterCarriedOverNotification,
  } = useContext(GlobalContext) as IGlobalContext;
  const dispatch = useDispatch();
  const allDevelopments = useSelector(getAllDevelopments);
  const keywordFilteredDevelopmentIds = useSelector(getKeywordFilteredDevelopmentIds);
  const salesContext = useContext(SalesContext) as ISalesContext;
  const globalContext = useContext(GlobalContext) as IGlobalContext;
  // const { editMode } = useContext(SalesContext) as ISalesContext;
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<{ [key: string]: mapboxgl.Marker; }>({});
  const popupsRef = useRef<{ [key: string]: mapboxgl.Popup; }>({});
  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);

  const editMode = salesContext?.editMode ?? false;
  const view = globalContext?.view ?? '';
  const [mapShowing, setMapShowing] = useState(props.onHomePage || view === "map");
  const [mapLoaded, setMapLoaded] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  const [selectedDevelopment, setSelectedDevelopment] = useState<Development | null>(null);

  // Reference to the Portal root
  const portalRoot = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on('load', () => {
        console.log('Map loaded');
        setMapLoaded(true);
      });
    }
  }, []);

  // useEffect(() => {
  //   // Create a div to render the portal if it doesn't exist
  //   if (!portalRoot.current) {
  //     portalRoot.current = document.createElement("div");
  //     document.body.appendChild(portalRoot.current);
  //   }

  //   return () => {
  //     // Clean up the portal root
  //     if (portalRoot.current) {
  //       document.body.removeChild(portalRoot.current);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   console.log('useEffect triggered');
  //   console.log('mapLoaded:', mapLoaded);
  //   console.log('keywordFilteredDevelopmentIds:', keywordFilteredDevelopmentIds);

  //   if (mapLoaded) {
  //     console.log('Map is loaded');
  //     if (keywordFilteredDevelopmentIds) {
  //       console.log('keywordFilteredDevelopmentIds is available');
  //       console.log('Loading data on map');
  //       loadDataOnMap();
  //       setFinishLoading(true);
  //     } else {
  //       console.log('keywordFilteredDevelopmentIds is not available yet');
  //     }
  //   } else {
  //     console.log('Map is not loaded yet');
  //   }
  // }, [mapLoaded, keywordFilteredDevelopmentIds]);



  // useEffect(() => {
  //   if (mapLoaded && keywordFilteredDevelopmentIds) {
  //     console.log('keywordFilteredDevelopmentIds:', keywordFilteredDevelopmentIds);
  //     console.log('Loading data on map');
  //     loadDataOnMap();
  //     setFinishLoading(true);
  //   }
  // }, [mapLoaded, keywordFilteredDevelopmentIds]);


  useEffect(() => {
    if (props.mapShowing || view === "map") {
      setMapShowing(true);
    }
  }, [view, props.mapShowing]);

  useEffect(() => {
    try {
      mapRef.current = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v12",
        center: [-0.0999, 51.5085224],
        // center: [-0.145949, 51.5085224],
        zoom: 12,
      });

      mapRef.current.on('error', (e) => {
        console.error('Mapbox error:', e);
      });

      // Add zoom and rotation controls to the map on the top left.
      mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");

      // Add geolocation controls to the map on the top left.
      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        }),
        "top-left"
      );
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, []);



  useEffect(() => {
    if (!mapShowing) return;
    removeAllMarkersAndPopupsOnMap();
    loadDataOnMap();
  }, [searchName, mapShowing, keywordFilteredDevelopmentIds, dispatch]);



  function removeAllMarkersAndPopupsOnMap() {
    if (!isRealMobile) {
      Object.keys(popupsRef.current).forEach((id) => {
        popupsRef.current[id].remove();
        delete popupsRef.current[id];
      });
    }

    Object.keys(markersRef.current).forEach((id) => {
      markersRef.current[id].remove();
      delete markersRef.current[id];
    });

    // Reset selected development if any
    if (isRealMobile) {
      setSelectedDevelopment(null);
    }
  }

  function loadDataOnMap() {


    keywordFilteredDevelopmentIds.forEach((id: any, index: any) => {
      if (allDevelopments[id] && !markersRef.current[id]) {
        const development = allDevelopments[id];
        let popup: any;

        if (!isRealMobile) {
          // POPUP
          const MyPopup = (
            <GlobalContext.Provider
              value={{
                lang: lang,
                setLang: () => { },
                view: view,
                setView: () => { },
                renderNum: renderNum,
                isMobile: isMobile,
                setIsMobile: () => { },
                isRealMobile: isRealMobile,
                setIsRealMobile: () => { },
                isSmallScreen: isSmallScreen,
                setIsSmallScreen: () => { },
                permission: permission,
                setPermission: () => { },
                showingTools: showingTools,
                setShowingTools: () => { },
                showContactPopup: showContactPopup,
                setShowContactPopup: () => { },
                showPermissionPopup: showPermissionPopup,
                setShowPermissionPopup: () => { },
                adminOn: adminOn,
                setAdminOn: () => { },
                showActionPopup: showActionPopup,
                setShowActionPopup: () => { },
                searchName: searchName,
                setSearchName: () => { },
                showFilterCarriedOverNotification: showFilterCarriedOverNotification,
                setShowFilterCarriedOverNotification: () => { },
              }}
            >
              <SalesContext.Provider value={salesContext}>
                <Popup obj={development} isMap={true} />
              </SalesContext.Provider>
            </GlobalContext.Provider>
          );

          const html = ReactDOMServer.renderToString(MyPopup);

          popup = new mapboxgl.Popup({
            offset: 25,
            className: "id-" + development.id,
            focusAfterOpen: false
          })
            .setHTML(html)
            .setMaxWidth("none");
          popupsRef.current[development.id] = popup;
        }




        // MARKER
        const jsxString = ReactDOMServer.renderToStaticMarkup(
          <IncludeHouseIcon width="15" height="15" />
        );
        const domParser = new DOMParser();
        const svgElement = domParser.parseFromString(
          jsxString,
          "image/svg+xml"
        ).documentElement;
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const svgAsDataURL =
          "data:image/svg+xml," + encodeURIComponent(svgString);

        enum PurpleShades {
          Base99 = "#edeaf0",
          Base7 = "#edeaf0",
          Base6 = "#edeaf0",
          Base5 = "#edeaf0",
          Base4 = "#edeaf0",
          Base3 = "#edeaf0",
          Base2 = "#edeaf0",
          Base1 = "#edeaf0",
        }

        const color =
          PurpleShades[
          ("Base" + development.zoneMain) as keyof typeof PurpleShades
          ];

        const MyMarker = (
          <div
            id={String(development.id)}
            className={`border w-[100px] h-[30px] whitespace-nowrap border-secondary-dark/10 shadow-md rounded-lg font-normal text-secondary-dark flex flex-col justify-center gap-1 p-1 px-2 hover:cursor-pointer`}
            style={{ backgroundColor: color }}
          >
            <div id={String(development.id)} className="absolute right-1 top-1 inset-0 h-[80%] w-[30%] ml-auto rounded-lg bg-gradient-to-r from-transparent to-tertiary-light pointer-events-none"></div>
            <p id={String(development.id)} className="flex gap-2 items-center pr-2 overflow-hidden">
              {development.house && (
                <img id={String(development.id)} className="opacity-60" src={svgAsDataURL} alt="House Icon" />
              )}
              {development.name}
            </p>
            <button
              id={String(development.id)}
              className="marker-remove absolute top-[-10px] right-[-10px] text-lg bg-secondary-light p-5 rounded-full w-5 h-5 items-center justify-center text-white shadow-md"
            >
              ✕
            </button>
          </div>
        );

        const markerHtml = ReactDOMServer.renderToString(MyMarker);

        const markerEl = document.createElement("div");
        markerEl.innerHTML = markerHtml;
        markerEl.addEventListener("mousedown", handleRemoveDevelopment);


        const marker = new mapboxgl.Marker(markerEl)
          .setLngLat([development.longitude, development.latitude])
          .addTo(mapRef.current || new mapboxgl.Map({ container: 'map-container' }));


        if (!isRealMobile) marker.setPopup(popup);

        marker
          .getElement()
          .addEventListener("mouseenter", () => marker.togglePopup());
        marker
          .getElement()
          .addEventListener("mouseleave", () => marker.togglePopup());

        markersRef.current[development.id] = marker; // Store the marker instance

        if (!isMobile) popupsRef.current[development.id] = popup;
      }
    });

  }

  function handleRemoveDevelopment(event: any) {
    if (isRealMobile) {
      console.log(event.target);
      console.log(event.target.id);
      setSelectedDevelopment(event.target.id);
    }
    if (!event.target.matches(".marker-remove")) return;

    // Read the id of the button
    const buttonId = event.target.id;

    // Remove the marker from the map
    if (markersRef.current[buttonId]) {
      markersRef.current[buttonId].remove();
      delete markersRef.current[buttonId];
    }

    // Remove the popup from the map
    if (popupsRef.current[buttonId]) {
      popupsRef.current[buttonId].remove();
      delete popupsRef.current[buttonId];
    }

    // Dispatch the removeDevelopment action
    dispatch(removeDevelopment(+buttonId));

    event.stopPropagation();
  }



  return (
    <>
      <div className="relative h-full w-full">
        {/* {!finishLoading &&
        <div className="absolute left-0 top-0 z-[9999999999] bg-gray-50 flex items-center justify-center h-full w-full" >
          <SpinnerCircles />
        </div>
      } */}

        <div
          id="map"
          style={{
            height: `calc(100vh - ${(headerHeight + menubarHeight)}px)`,
            width: props.onHomePage ? "100%" : "100vw",
          }}
          className={`${props.rounded ? "rounded-xl" : ""} ${props.onHomePage ? "hide mapboxgl-map" : (editMode ? "show mapboxgl-map" : "hide mapboxgl-map")}`}
        ></div>

        {/* Fixed Popup for Mobile using React Portal */}
        {/* {isRealMobile && selectedDevelopment && portalRoot.current && ReactDOM.createPortal(
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 z-50">
          <Popup
            obj={allDevelopments[+selectedDevelopment]}
            setCurrentObj={() => setSelectedDevelopment(null)}
            isMap={true}
          />
        </div>,
        // portalRoot.current
      )} */}

      </div>
      {
        isRealMobile && selectedDevelopment &&
        <div className="fixed bottom-14 left-0 right-0 p-4 z-50">
          <Popup
            obj={allDevelopments[+selectedDevelopment]}
            setCurrentObj={() => setSelectedDevelopment(null)}
            isMap={true}
          />
        </div>
      }
    </>
  );
};
