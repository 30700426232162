import React, { useContext, useEffect, useState } from "react";
import styles from "./ListView.module.css";
import ListBlock from "../Components/List/ListBlock";
import { useDispatch, useSelector } from "react-redux";
import { clearFilter, getAllDevelopments, getFilteredDevelopmentIds, getKeywordFilteredDevelopmentIds, getSortedDevelopmentIds } from "../../../features/developments/developmentsSlice";
import { SalesContext } from "../../../context/SalesContext";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import { IncludeHouseIcon } from "../../../assets/assetExport";
import { GlobalContext } from "../../../context/GlobalContext";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";
import { selectLayoutHeaderHeight, selectLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";
import imgNoResult from "../../../assets/media/background/no-result.png";

function importAll(r: any) {
  let images: any = {};
  r.keys().map((item: any) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../../../assets/media/developmentImages",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export default function ListView(props: any) {
  const sortedDevelopmentIds = useSelector(getSortedDevelopmentIds);
  const allDevelopments = useSelector(getAllDevelopments);
  const { showFilter, setSort } = useContext(SalesContext) as ISalesContext;
  const [finishLoading, setFinishLoading] = useState(false);
  const headerHeight = useSelector(selectLayoutHeaderHeight);
  const menubarHeight = useSelector(selectLayoutMenubarHeight);
  const dispatch = useDispatch();

  useEffect(() => {
    setSort("name");
  }, []);

  useEffect(() => {
    if (sortedDevelopmentIds && allDevelopments) {
      setFinishLoading(true);
    }
  }, [sortedDevelopmentIds, allDevelopments, setFinishLoading]);


  return (
    !finishLoading ?
      <div
        style={{ height: "calc(100vh - " + (headerHeight + menubarHeight) + "px)" }}
        className=" bg-gray-50 flex items-center justify-center w-full" >
        <SpinnerCircles />
      </div>
      :
      ((sortedDevelopmentIds && sortedDevelopmentIds.length === 0)
        ?
        <div className="flex flex-col items-center justify-center h-full w-full" style={{ height: "calc(100vh - " + (headerHeight + menubarHeight) + "px)" }}>
          <img className="absolute top-0 left-0 w-full h-full object-cover filter grayscale opacity-15" src={imgNoResult} alt="decorative illustration for no result" />
          <div className="relative z-10 flex flex-col -mt-20 items-center justify-center text-center">
            <p className="text-2xl font-semibold text-gray-700 mb-4">No results found</p>
            <p className="text-lg text-gray-600 mb-6">Try adjusting your search or filters to find your dream property.</p>
            <button
              className="px-4 py-2 bg-main text-white rounded-lg hover:bg-secondary-dark transition duration-300"
              onClick={() => {
                dispatch(clearFilter());
                // setLocalFilters(initialFilter);
              }}
            >
              Reset Filters
            </button>
          </div>
        </div>
        :
        <>
          <div className={`${styles.gridContainer} ${showFilter && styles.withFilter} relative`}
            id="print-list-view">

            {sortedDevelopmentIds && sortedDevelopmentIds.length > 0 && sortedDevelopmentIds.map((id: any) => {
              const obj = allDevelopments[id];
              const imageId = String(obj.id).padStart(4, '0');
              const imageUrl = images[`NV${imageId.slice(-4)}.jpg`];

              return (
                <ListBlock
                  key={obj["id"]}
                  obj={{ ...obj, url: imageUrl }}
                  renderWithSort={props.renderWithSort}
                  sort={props.sort}
                />
              );
            })}
          </div>
          <p className="including-houses" style={{ marginLeft: "60px", marginBottom: "40px", fontSize: "20px", display: "flex", alignItems: "center" }}>  <IncludeHouseIcon width="18" height="18" />&nbsp; Including houses</p>
        </>
      )
  );
}
