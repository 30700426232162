import React, { useEffect, useState, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./TubeView.css";
import TubeSVG from "../Components/Tube/TubeSVG";
import TubeBlock from "../Components/Tube/TubeBlock";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import { SalesContext } from "../../../context/SalesContext";
import {
  getAllDevelopments,
  getFilteredDevelopmentIds,
  getKeywordFilteredDevelopmentIds,
} from "../../../features/developments/developmentsSlice";
import { IGlobalContext, ISalesContext } from "../../../types/types";
import { GlobalContext } from "../../../context/GlobalContext";
import Popup from "../Components/Common/Popup";
import { QuestionIcon } from "../../../assets/assetExport";
import { SpinnerCircles } from "../../../util/Spinners/Spinners";
import { selectLayoutMenubarHeight } from "../../../features/layouts/layoutsSlice";

const plusSign = require("../../../assets/media/icons/plus-sign-squarecorner-white.png");
const minusSign = require("../../../assets/media/icons/minus-sign-squarecorner-white.png");
const restoreSign = require("../../../assets/media/icons/refresh-white5.png");
const hideSign = require("../../../assets/media/icons/hide-white3.png");
const unhideSign = require("../../../assets/media/icons/unhide-white.png");
const keysToLinesCN = require("../../../assets/media/tubemap/keys-to-lines.png");
const keysToLinesEN = require("../../../assets/media/tubemap/keys-to-lines-en.png");

const stationData = require("../../../data/stationDataFinal.json");
const stationInfo: any = {};

// ===== helper: create stationInfo{} ===== //
stationData.map((x: any) => {
  stationInfo[x["Unique-id"]] = {
    stationId: x["Unique-id"],
    transform: x["Matrix"],
    station: x["Name"],
  };
});

export default function TubeView(props: any) {
  const dispatch = useDispatch();

  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const keywordFilteredDevelopmentIds = useSelector(
    getKeywordFilteredDevelopmentIds
  );
  const allDevelopments = useSelector(getAllDevelopments);

  const { isMobile, searchName, lang, isRealMobile } = useContext(
    GlobalContext
  ) as IGlobalContext;
  const [dataForMap, setDataForMap] = useState<any[]>([]);
  const [showLabels, setShowLabels] = useState(true);
  const [currentObj, setCurrentObj] = useState<any>();
  const [retry, setRetry] = useState(0);
  const [showKeysToLines, setShowKeysToLines] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  // useEffect(() => {
  //   loadSVGData(searchFilterMapData(filteredDevelopments));
  // }, [filteredDevelopments, searchName]);

  useEffect(() => {
    if (dataForMap.length > 0) {
      setFinishLoading(true);
    }
  }, [dataForMap, setFinishLoading]);

  useEffect(() => {
    console.log(3);
    if (
      keywordFilteredDevelopmentIds &&
      keywordFilteredDevelopmentIds.length > 0
    ) {
      const developmentsToLoad = keywordFilteredDevelopmentIds
        .map((id) => allDevelopments[id])
        .filter(Boolean);
      loadSVGData(developmentsToLoad);
    } else {
      console.error(
        "keywordFilteredDevelopmentIds is empty",
        keywordFilteredDevelopmentIds
      );
      // Handle the error case, maybe set an empty Map
      setDataForMap([]);
    }
  }, [keywordFilteredDevelopmentIds]);

  function loadSVGData(data: any[]) {
    const dataForMap: any[] = [];

    // - create dataForMap from filteredDevelopments - //
    data.forEach((development: any) => {
      const existingStation = dataForMap.find(
        (item) => item.stationId === development.stationId
      );
      if (!existingStation) {
        // not in dataForMap
        dataForMap.push({
          station: development.station,
          stationId: development.stationId,
          transform: stationInfo[development.stationId]["transform"],
          developments: [{ ...development, y: 12 }],
        });
      } else {
        // exist in dataForMap
        const developments = existingStation.developments;
        developments.push({
          ...development,
          y: +developments[developments.length - 1].y + 12,
        });
      }
    });

    // ===== determine the whole block's height and width ===== //
    dataForMap.forEach((value) => {
      const devWidth = Math.max(
        ...value.developments.map((i: any) => i["name"].length)
      );
      const stationWidth = value["station"].length;
      value.width = (devWidth >= stationWidth ? devWidth : stationWidth) * 6.2;
      value.height = (value.developments.length + 1) * 21;
    });

    setDataForMap(dataForMap);
  }

  const Control = ({ zoomIn, zoomOut, resetTransform, setTransform }: any) => (
    <div className="svgview-zoom-button-container">
      <div>
        <button
          title="restore"
          type="button"
          className="svgview-zoom-button svgview-zoom-button-restore"
          onClick={(e) => {
            e.stopPropagation();
            setTransform(0, 0, 1);
          }}
        >
          <img src={restoreSign} alt="restore sign" />
        </button>
      </div>
      <div>
        <button
          title="zoom in"
          type="button"
          className="svgview-zoom-button svgview-zoom-button-in"
          onClick={(e) => {
            e.stopPropagation();
            zoomIn();
          }}
        >
          <img src={plusSign} alt="plus sign" />
        </button>
        <button
          title="zoom out"
          type="button"
          className="svgview-zoom-button svgview-zoom-button-out"
          onClick={(e) => {
            e.stopPropagation();
            zoomOut();
          }}
        >
          <img src={minusSign} alt="minus sign" />
        </button>
      </div>
      <div>
        <button
          title="show label"
          type="button"
          className="svgview-zoom-button svgview-zoom-button-hide"
          onClick={(e) => {
            e.stopPropagation();
            setShowLabels(!showLabels);
          }}
        >
          <img src={showLabels ? hideSign : unhideSign} alt="show label sign" />
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    if (
      props.zoomed &&
      transformComponentRef.current &&
      mapContainerRef.current
    ) {
      const { offsetWidth: width, offsetHeight: height } =
        mapContainerRef.current;
      transformComponentRef.current.setTransform(
        width * -0.26,
        height * -0.34,
        1.5
      );
    } else if (props.zoomed) {
      const timeout = setTimeout(() => setRetry(retry + 1), 100);
      return () => clearTimeout(timeout);
    }
  }, [props.zoomed, retry]);

  useEffect(() => {
    const handleResize = () => setRetry(retry + 1);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [retry]);

  return (
    <div
      className="svgview-container relative"
      id="print-tube-view"
      onClick={() => {
        setCurrentObj(null);
      }}
      ref={mapContainerRef}
    >
      {!finishLoading && (
        <div className="absolute left-0 top-0 z-[999999999999] bg-gray-50 flex items-center justify-center h-full w-full">
          <SpinnerCircles />
        </div>
      )}
      <div
        id="keys-to-lines"
        className={`absolute z-[999999999] left-24 top-8 ${
          showKeysToLines ? "rounded-xl" : "rounded-full"
        }`}
        // <div className={`absolute z-[999999999] right-3 top-3 p-1 pr-2 ${showKeysToLines ? "" : "rounded-full"} border border-tertiary-light bg-gray-50 `}
        style={{ boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)" }}
      >
        {!showKeysToLines ? (
          <div
            className="flex p-1 pr-2 rounded-full border-[0.5px]  border-tertiary-light bg-white bg-opacity-60 backdrop-blur-sm items-center gap-1 hover:cursor-pointer"
            onClick={() => {
              setShowKeysToLines(true);
            }}
          >
            <QuestionIcon width="16" height="16" />
            <span className="text-sm text-main font-medium font-sans">
              Keys to Lines
            </span>
          </div>
        ) : (
          <div className="relative border-[0.5px] rounded-xl border-tertiary-light ">
            <p
              className="absolute top-2 right-3 text-sm text-gray-400 font-light hover:cursor-pointer hover:text-gray-800"
              onClick={() => {
                setShowKeysToLines(false);
              }}
            >
              ✕
            </p>
            <img
              className="rounded-lg w-60"
              src={lang ? keysToLinesEN : keysToLinesCN}
              alt="keys to lines for London tube"
            />
          </div>
        )}
      </div>
      <TransformWrapper
        key={isMobile ? "mobile" : "desktop"}
        initialScale={isMobile ? 5 : 1}
        initialPositionX={isMobile ? -800 : 0}
        initialPositionY={isMobile ? -200 : 0}
        doubleClick={{ disabled: false }}
        ref={transformComponentRef}
      >
        {(utils) => (
          <React.Fragment>
            <Control {...utils} />
            <TransformComponent>
              <div className="svg-zoom w-full">
                <TubeSVG id="tube-svg" />
                {showLabels && (
                  <svg
                    id="dev-svg"
                    viewBox="0 0 2542.6 1794.3"
                    // viewBox="0 0 2939.38 1794.33"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                    // style={{
                    //     width: isWidthHideFilter ? (isMobile ? "auto" : "100vw") : "calc(100vw - 350px)",
                    //     height: isWidthHideFilter ? (isMobile ? "calc(100vh - 207.13px)" : "auto") : "auto"
                    // }}
                    enableBackground="new 0 0 1247.244 907.087"
                  >
                    {dataForMap.length > 0 &&
                      dataForMap.map((obj, index) => {
                        return (
                          <TubeBlock
                            obj={obj}
                            key={index}
                            setCurrentObj={setCurrentObj}
                          />
                        );
                      })}
                  </svg>
                )}
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
      {currentObj && Object.keys(currentObj).length > 0 && (
        <div
          className={`${
            isRealMobile ? "fixed bottom-20 left-0 w-full p-4" : ""
          }`}
        >
          <Popup obj={currentObj} setCurrentObj={setCurrentObj} />
        </div>
      )}
    </div>
  );
}
