import React, {
  useState,
  useEffect,
  createContext,
} from "react";
import { App } from "../app/App";
import { IGlobalContext } from "../types/types";
import { checkPermission } from "../api/user";
import {
  asyncFetchDevelopments,
  getAllDevelopments,
  getAllDevelopmentsStatus,
  getFilteredDevelopmentIds,
  setPriceOriginal,
} from "../features/developments/developmentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../app/store";

export const GlobalContext = createContext<IGlobalContext | null>(null);

export const GlobalContainer: React.FC = () => {
  const [lang, setLang] = useState<boolean>(
    new URLSearchParams(window.location.search).get("language") === "english" ||
    true
  );
  const dispatch = useDispatch<AppDispatch>();
  const allDevelopments = useSelector(getAllDevelopments);

  const filteredDevelopmentIds = useSelector(getFilteredDevelopmentIds);
  const developmentsStatus = useSelector(getAllDevelopmentsStatus);

  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isRealMobile, setIsRealMobile] = useState<boolean>(true);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(true);
  const [permission, setPermission] = useState(0);
  const [showingTools, setShowingTools] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [adminOn, setAdminOn] = useState(false);
  const [searchName, setSearchName] = useState<string>("");
  const [view, setView] = useState<string>("");
  const [showFilterCarriedOverNotification, setShowFilterCarriedOverNotification] = useState(false);

  const renderNum = (num: number) => {
    if (num == 1) {
      return "TBC";
    } else {
      return "£" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };





  useEffect(() => {
    if (developmentsStatus === "idle") {
      dispatch(asyncFetchDevelopments());
    }
  }, [dispatch]);

  const _getMinMaxPrice = (developments: any) => {
    const prices = Object.values(developments).map((data: any) => ({
      max: data.maxPrice,
      min: data.minPrice && data.minPrice !== 1 && data.minPrice !== 0 ? data.minPrice : Infinity
    }));

    const maxPrice = Math.max(...prices.map(p => p.max));
    const minPrice = Math.min(...prices.map(p => p.min));

    return [minPrice, maxPrice];
  };

  useEffect(() => {
    // WHEN FIRST LOADED //
    if (allDevelopments && Object.keys(allDevelopments).length > 0) {
      const [minPrice, maxPrice] = _getMinMaxPrice(allDevelopments);
      dispatch(setPriceOriginal([minPrice, maxPrice]));
    }
  }, [allDevelopments]);

  /*
  TODO: when filteredDevelopments changed, need to update the filter's price, but this will result in a loop
  useEffect(() => {
    // WHEN FILTERED DEVELOPMENTS ARE CHANGED //
    if (filteredDevelopmentIds && filteredDevelopmentIds.length > 0 && allDevelopments && (Object.keys(allDevelopments).length !== filteredDevelopmentIds.length)) {
      const filteredDevelopments = filteredDevelopmentIds.reduce((acc: any, id: number) => {
        if (allDevelopments[id]) {
          acc[id] = allDevelopments[id];
        }
        return acc;
      }, {});

      const [minPrice, maxPrice] = _getMinMaxPrice(filteredDevelopments);
      dispatch(setFilter({ filter: "price", value: [minPrice, maxPrice] }));
    }
  }, [filteredDevelopmentIds, allDevelopments]);
*/



  useEffect(() => {
    (async () => {
      try {
        const response = await checkPermission();
        if (response.status === 200) {
          setPermission(5);
          return;
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isAdminParam = queryParams.get("admin");

    if (isAdminParam === "on") {
      setAdminOn(true);
    }
  }, []);


  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setIsMobile(window.innerWidth < 650);
        setIsRealMobile(window.innerWidth < 500);
        setIsSmallScreen(window.innerWidth < 870);
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (showContactPopup || showActionPopup) {
      if (html) html.style.overflow = "hidden";
    } else {
      if (html) html.style.overflow = "unset";
    }
  }, [showContactPopup, showActionPopup]);

  return (
    <GlobalContext.Provider
      value={{
        lang,
        setLang,
        isMobile,
        setIsMobile,
        permission,
        setPermission,
        showingTools,
        setShowingTools,
        showContactPopup,
        setShowContactPopup,
        showPermissionPopup,
        setShowPermissionPopup,
        adminOn,
        setAdminOn,
        showActionPopup,
        setShowActionPopup,
        isRealMobile,
        setIsRealMobile,
        isSmallScreen,
        setIsSmallScreen,
        searchName,
        setSearchName,
        renderNum,
        view,
        setView,
        showFilterCarriedOverNotification,
        setShowFilterCarriedOverNotification,
      }}
    >
      <App />
    </GlobalContext.Provider>
  );
};
