import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { IGlobalContext } from "../../types/types";
import { HashLink } from 'react-router-hash-link';

const qrFollowUs = require("../../assets/media/logo/qrCode/nvre-wechat-subscribe-qrcode.png");
const qrChatToUs = require("../../assets/media/logo/qrCode/nvre-wechat-qrcode.png");
const arlaLogo = require("../../assets/media/logo/certificate/arla-logo.png");
const prsLogo = require("../../assets/media/logo/certificate/prs-logo.png");
const dpsLogo = require("../../assets/media/logo/certificate/dps-logo.png");
const nvreLogoPurple = require("../../assets/media/logo/company/nvreLogoPurple.png");

function Footer(props: any) {
    const { lang } = useContext(GlobalContext) as IGlobalContext;

    return (
        <footer className={`flex flex-col items-start gap-10 border-t p-10 relative z-[99] text-gray-800`}>
            <img className="h-8 w-auto" src={nvreLogoPurple} alt="nvre-logo" />
            <address className="flex flex-col sm:flex-row justify-between w-full gap-20 sm:gap-3 pt-10  not-italic">
                <div className="address">
                    <p className="text-secondary-light text-lg mb-4">{lang ? "OFFICE" : "办公室"}</p>
                    <p className="">
                        16 Berkeley Street, Mayfair <br />
                        London<br />
                        W1J 8DZ<br />
                        United Kingdom
                    </p>
                </div>
                <div className="contact-us">
                    <p className="text-secondary-light text-lg mb-4">{lang ? "CONTACT US" : "联系我们"}</p>
                    <div className="">
                        <span className="text-gray-400 text-sm font-semibold">{lang ? "EMAIL: " : "邮箱："}</span>
                        <a href="mailto:contact@nvre.co.uk">contact@nvre.co.uk</a>
                    </div>
                    <div className="">
                        <span className="text-gray-400 text-sm font-semibold">{lang ? "OFFICE: " : "办公室："}</span>
                        <span>
                            +44 (0)20 3907 4024
                        </span>
                    </div>
                    <div className="">
                        <span className="text-gray-400 text-sm font-semibold">{lang ? "FORM: " : "咨询表格："}</span>
                        <span>{lang ? "Fill out a form " : "在"}<HashLink className="text-main opacity-60 font-semibold" to="/#contact-us">{lang ? "here" : "这里"}</HashLink>{lang ? "." : "填写联系表格。"}</span>
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="flex flex-col items-center">
                        <img className="w-20" src={qrChatToUs} />
                        <p className="text-sm font-medium text-secondary-light">{lang ? <span>Chat To Us <br />On WeChat</span> : <span>一对一咨询</span>}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <img className="w-20" src={qrFollowUs} />
                        <p className="text-sm font-medium text-secondary-light">{lang ? "Follow Us" : "关注我们"}</p>
                    </div>
                </div>

            </address>
            <div className="flex flex-col lg:flex-row justify-between w-full gap-4 border-t border-gray-100 pt-10">
                <div className="text-xs opacity-40 lg:max-w-[300px]">
                    <p>&copy; {lang ? "Copyright 2023 NVRE" : "2023 NVRE版权所有"}</p>
                    <p className="mt-4">{lang ? "NVRE is a business name used by New Vision Real Estate Limited, registered in England and Wales. Company No.: 12987204. VAT Number: 386337268." : "NVRE是由注册在英格兰和威尔士的 New Vision Real Estate Limited 所使用的商业名称。公司注册号：12987204。增值税号：386337268。"}</p>
                </div>
                <div className="mt-10 sm:mt-0 flex flex-col sm:flex-row gap-5 sm:gap-2 items-start sm:items-center self-start sm:self-end">
                    <p className="font-bold whitespace-nowrap text-secondary-dark">{lang ? "NVRE IS A MEMBER OF" : "NVRE为成员于"}</p>
                    <img className="h-12" src={arlaLogo} alt="arla logo" />
                    <img className="h-12" src={prsLogo} alt="prs logo" />
                    <img className="h-12" src={dpsLogo} alt="dps logo" />
                </div>
            </div>
        </footer >
    );
}

export default Footer;