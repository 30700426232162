import React, { useContext } from "react";
import Info from "./Info";
import Contact from "../../../Contact/Contact";
import { GlobalContext } from "../../../../context/GlobalContext";
import { IGlobalContext } from "../../../../types/types";

export default function Popup(props: any) {
  const { isRealMobile } = useContext(GlobalContext) as IGlobalContext;

  return (
    <div
      className={` ${isRealMobile ? (props.isMap ? "" : "w-full shadow-lg ") : (!props.isMap ? "absolute top-8 left-24 w-[400px]" : "w-[400px]")} bg-white  shadow-lg border rounded-lg`}
    // className={` ${isRealMobile ? "w-full shadow-lg " : (!props.isMap ? "absolute top-8 left-24 w-[400px]" : "w-[400px]")} bg-white  shadow-lg border rounded-lg`}
    >
      {(!props.isMap || isRealMobile) && (
        <p
          className={` absolute ${isRealMobile ? "top-6 left-7 text-white font-thin text-lg" : "top-2 right-2 text-white font-bold text-xl"}    hover:cursor-pointer`}
          onClick={() => {
            props.setCurrentObj(null);
          }}
        >
          ✕
        </p>
      )}
      <div className={`${isRealMobile ? "flex h-full " : ""}`}>
        <Info obj={props.obj} customiseImg={true} />
      </div>
      {!isRealMobile &&
        <div className="p-6 border-t border-t-gray-200/70 bg-secondary-light/5">
          <Contact page="onmap" />
        </div>
      }
    </div>
  );
}
